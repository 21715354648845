import { BLOCKS } from '@contentful/rich-text-types'

import { Box, shadows, spacing } from '@fortum/elemental-ui'

import type { RichTextDocument } from '@/shared/components/RichText'
import { RichText, renderRichTextHeading } from '@/shared/components/RichText'
import { StyledHeading } from '@/shared/components/StyledHeading'

type Item = {
  title?: string | null
  content?: RichTextDocument | null
}

export type ItemCardProps = {
  item: Item
  visible: boolean
}

export const ItemCard = ({ item, visible }: ItemCardProps) => {
  return (
    <Box
      p={{ default: spacing.xxs, m: spacing.m }}
      boxShadow={shadows.m}
      borderRadius={spacing.xxxs}
      display={visible ? 'block' : 'none'}
    >
      <StyledHeading level={3} styledAs={2} mb={spacing.xs}>
        {item.title}
      </StyledHeading>
      <RichText
        document={item.content}
        customOptions={{
          renderNode: {
            // Render heading level 2 as level 4
            [BLOCKS.HEADING_2]: renderRichTextHeading(4),
          },
        }}
      />
    </Box>
  )
}
