'use client'

import { useSearchParams } from 'next/navigation'
import { useMemo } from 'react'

import type { FaqEntry, FaqItemEntry, FaqSectionEntry } from '@/shared/contentful/types'
import { FaqPageJsonLd } from '@/shared/scripts/StructuredDataMarkup'

import { ITEM_PARAM } from './constants'
import { AllTopicsSection } from './parts/AllTopicsSection'
import { PopularTopicSection } from './parts/PopularTopicSection'
import { TitleSection } from './parts/TitleSection'

export type FaqSectionProps = FaqSectionEntry & {
  faq: FaqEntry
  faqItems: FaqItemEntry[]
}

export const FaqSection = ({ title, faq, cta, faqItems }: FaqSectionProps) => {
  const searchParams = useSearchParams()
  const selectedSlug = searchParams.get(ITEM_PARAM) ?? undefined
  const { topics, trendingItems, popularTopics } = faq
  const allItems = topics?.items?.flatMap((topic) => topic?.itemsCollection?.items).filter(Boolean)

  const selectedId = useMemo(() => {
    const selected = allItems?.find((item) => item.slug === selectedSlug) ?? allItems?.at(0)
    return selected?.sys.id
  }, [selectedSlug, allItems])

  const faqTopics = topics?.items?.filter(Boolean) ?? []

  return (
    <>
      {FaqPageJsonLd(title, allItems)}
      <TitleSection title={title} trendingItems={trendingItems} />
      <PopularTopicSection popularTopics={popularTopics} cta={cta} />
      <AllTopicsSection topics={faqTopics} faqItemsDetails={faqItems} selectedId={selectedId} />
    </>
  )
}
