import { useTranslations } from 'next-intl'

import { Box, Section, spacing } from '@fortum/elemental-ui'

import { StyledHeading } from '@/shared/components/StyledHeading'
import { StyledLink } from '@/shared/components/StyledLink'
import type { FaqEntry, FaqSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import { FaqCta } from './FaqCta'
import { PopularTopicLinks } from './PopularTopicLinks'
import { ALL_TOPICS_HEADING_ID } from '../constants'

export type PopularTopicSectionProps = {
  popularTopics: FaqEntry['popularTopics']
  cta: FaqSectionEntry['cta']
}

export const PopularTopicSection = ({ popularTopics, cta }: PopularTopicSectionProps) => {
  const theme = useTheme()
  const { colors } = theme
  const t = useTranslations('faq')

  const handleTopicClick = () => {
    const element = document.getElementById(ALL_TOPICS_HEADING_ID)
    setTimeout(() => element?.scrollIntoView({ behavior: 'smooth' }), 100)
  }

  return (
    <Box background={colors.backgroundSecondary}>
      <Section pv={spacing.m}>
        <Box gap={{ default: spacing.xs, l: spacing.m }} display="flex" flexDirection="column">
          <Box display="flex" alignItems="flex-end" gap={spacing.xs}>
            <StyledHeading level={2} styledAs={3}>
              {t('popularTopics')}
            </StyledHeading>
            <StyledLink
              mb={{ default: spacing.xxxxs, s: spacing.xxxs }}
              onClick={handleTopicClick}
              scroll={false}
              data-testid="see-all-topics"
            >
              {t('seeAllTopics')}
            </StyledLink>
          </Box>
          <PopularTopicLinks topics={popularTopics} />
          {cta && <FaqCta {...cta} />}
        </Box>
      </Section>
    </Box>
  )
}
