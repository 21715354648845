import { useTranslations } from 'next-intl'
import styled, { css } from 'styled-components'

import { Box, Col, ContentText, Row, Section, fontSizes, spacing } from '@fortum/elemental-ui'

import { StyledHeading } from '@/shared/components/StyledHeading'
import { StyledLink } from '@/shared/components/StyledLink'
import type { FaqEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import { getClickColor, getHoverColor } from '@/shared/utils/colors'
import { stringifyParams } from '@/shared/utils/url'

import { ITEM_CARDS_ID, ITEM_PARAM } from '../constants'

export type TitleSectionProps = {
  title: string | undefined | null
  trendingItems: FaqEntry['trendingItems']
}

export const TitleSection = ({ title, trendingItems }: TitleSectionProps) => {
  const theme = useTheme()
  const { colors } = theme
  const t = useTranslations('faq')

  return (
    <Section pv={spacing.m} background={colors.backgroundLightOnDarkGradient}>
      <Row display="flex" justifyContent="center">
        <Col xl={10}>
          <Box gap={spacing.s} display="flex" flexDirection="column">
            {title && (
              <StyledHeading level={1} color={colors.textLightOnDark}>
                {title}
              </StyledHeading>
            )}
            <Box gap={spacing.xxs} display="flex" flexDirection="column">
              <StyledHeading level={3} styledAs={5} color={colors.textLightOnDark}>
                {t('trendingQuestions')}
              </StyledHeading>
              {trendingItems && (
                <Box display="flex" flexWrap="wrap" gap={spacing.xxs}>
                  {trendingItems.items?.map((question) => {
                    const handleClick = () => {
                      const element = document.getElementById(ITEM_CARDS_ID)
                      setTimeout(() => element?.scrollIntoView({ behavior: 'smooth' }), 100)
                    }

                    return (
                      <TrendingLink
                        data-testid="faq-trending-question"
                        noUnderline
                        key={question?.sys.id}
                        noUnderlineHover
                        display="flex"
                        scroll={false}
                        alignItems="center"
                        ph={spacing.xs}
                        pv={spacing.xxxs}
                        border={`2px solid ${colors.textLightOnDark}`}
                        borderRadius={spacing.m}
                        color={colors.textLightOnDark}
                        href={`?${stringifyParams({ [ITEM_PARAM]: question?.slug || undefined })}`}
                        onClick={handleClick}
                      >
                        <ContentText
                          fontSize={fontSizes.s.s}
                          maxWidth={{ default: '18rem', xs: '100%' }}
                          minWidth={0}
                          textColor={colors.textLightOnDark}
                        >
                          {question?.title}
                        </ContentText>
                      </TrendingLink>
                    )
                  })}
                </Box>
              )}
            </Box>
          </Box>
        </Col>
      </Row>
    </Section>
  )
}

const TrendingLink = styled(StyledLink)`
  ${({ theme }) => css`
    &:hover,
    &:focus {
      background: ${getHoverColor(theme, theme.colors.backgroundLightOnDark)};
    }

    &:active {
      background: ${getClickColor(theme, theme.colors.backgroundLightOnDark)};
    }
  `}
`
