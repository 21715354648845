import { useTranslations } from 'next-intl'
import styled from 'styled-components'

import { Col, Row, Section, spacing } from '@fortum/elemental-ui'

import { StyledHeading } from '@/shared/components/StyledHeading'
import type { FaqItemEntry, FaqTopicEntry } from '@/shared/contentful/types'

import { AllTopicsNav } from './AllTopicsNav'
import { ItemCards } from './ItemCards'
import { ALL_TOPICS_HEADING_ID } from '../constants'

export type AllTopicsSectionProps = {
  topics: FaqTopicEntry[]
  selectedId: string | undefined
  faqItemsDetails: FaqItemEntry[]
}

export const AllTopicsSection = ({
  topics,
  selectedId,
  faqItemsDetails,
}: AllTopicsSectionProps) => {
  const t = useTranslations('faq')

  return (
    <Section pt={spacing.m} pb={spacing.xxl}>
      <SectionHeading level={2} styledAs={3} mb={spacing.m} id={ALL_TOPICS_HEADING_ID}>
        {t('allTopics')}
      </SectionHeading>
      <Row rowGap={spacing.s}>
        <Col xl={4}>
          <AllTopicsNav topics={topics} selectedId={selectedId} />
        </Col>
        <Col xl={8}>
          <ItemCards topics={topics} faqItemsDetails={faqItemsDetails} selectedId={selectedId} />
        </Col>
      </Row>
    </Section>
  )
}

const SectionHeading = styled(StyledHeading)`
  scroll-margin-top: 5rem;
`
