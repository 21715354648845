import { Box, spacing } from '@fortum/elemental-ui'

import type { FaqTopicEntry } from '@/shared/contentful/types'

import { TopicAccordion } from './TopicAccordion'

export type AllTopicsNavProps = {
  topics: FaqTopicEntry[]
  selectedId: string | undefined
}

export const AllTopicsNav = ({ topics, selectedId }: AllTopicsNavProps) => {
  return (
    <Box tag="nav" display="flex" flexDirection="column" gap={spacing.xxs}>
      {topics.map((topic) => (
        <TopicAccordion key={topic.sys.id} topic={topic} selectedId={selectedId} />
      ))}
    </Box>
  )
}
