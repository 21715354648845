import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { Box, ContentText, LazyIcon, opacify, spacing } from '@fortum/elemental-ui'

import { Accordion2 } from '@/shared/components/Accordion2'
import type { IconId } from '@/shared/components/Icon'
import { StyledLink } from '@/shared/components/StyledLink'
import type { FaqTopicEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import { stringifyParams } from '@/shared/utils/url'

import { ITEM_CARDS_ID, ITEM_PARAM } from '../constants'

export type TopicAccordionProps = {
  topic: FaqTopicEntry
  selectedId: string | undefined
}

export const TopicAccordion = ({ topic, selectedId }: TopicAccordionProps) => {
  const { colors } = useTheme()

  const items = topic?.itemsCollection?.items?.filter(Boolean)

  const hasSelectedItem = useMemo(() => {
    return items?.some((item) => item.sys.id === selectedId) ?? false
  }, [items, selectedId])

  const [isExpanded, setExpanded] = useState(hasSelectedItem)

  useEffect(() => {
    if (hasSelectedItem) {
      setExpanded(true)
    }
  }, [hasSelectedItem])

  const panelId = `faq-topic-accordion-${topic.sys.id}`

  const activeBg = opacify(colors.brandPrimary, 0.05)

  return (
    <Accordion2
      header={
        <Box display="flex" alignItems="center" gap={spacing.xxs}>
          {topic.icon && <LazyIcon iconId={topic.icon as IconId} size={48} />}
          <ContentText>{topic.title}</ContentText>
        </Box>
      }
      headerBoxProps={{
        p: spacing.xxs,
        pl: '0.75rem',
        borderLeft: `0.25rem solid ${hasSelectedItem ? colors.brandPrimary : 'transparent'}`,
        backgroundColor: hasSelectedItem ? activeBg : 'transparent',
        hover: { backgroundColor: activeBg },
      }}
      panelId={panelId}
      isExpanded={isExpanded}
      onToggle={() => setExpanded(!isExpanded)}
    >
      <Box display="flex" flexDirection="column">
        {items?.map((item) => {
          const isSelected = item.sys.id === selectedId

          const handleClick = () => {
            const element = document.getElementById(ITEM_CARDS_ID)
            setTimeout(() => element?.scrollIntoView({ behavior: 'smooth' }), 100)
          }

          return (
            <ItemLink
              key={item.sys.id}
              href={`?${stringifyParams({ [ITEM_PARAM]: item.slug ?? undefined })}`}
              noUnderlineHover
              color={isSelected ? colors.textPrimary : colors.textSecondary}
              aria-current={isSelected}
              p={spacing.xxs}
              pl="0.75rem"
              borderLeft={`0.25rem solid ${isSelected ? colors.brandPrimary : 'transparent'}`}
              backgroundColor={isSelected ? activeBg : 'transparent'}
              hover={{ backgroundColor: activeBg }}
              size="s"
              scroll={false}
              onClick={handleClick}
            >
              {item.title}
            </ItemLink>
          )
        })}
      </Box>
    </Accordion2>
  )
}

const ItemLink = styled(StyledLink)`
  /* Show focus outline inside hidden overflow container */
  &:focus-visible {
    box-shadow: none;
    outline-offset: -2px;
    outline: 2px solid ${({ theme }) => theme.tokens.focus.primary};
  }
`
