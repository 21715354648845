import { useMemo } from 'react'
import styled from 'styled-components'

import { Box } from '@fortum/elemental-ui'

import type { FaqItemEntry, FaqTopicEntry } from '@/shared/contentful/types'

import { ItemCard } from './ItemCard'
import { ScrollUpButton } from './ScrollUpButton'
import { ITEM_CARDS_ID } from '../constants'

export type ItemCardsProps = {
  topics: FaqTopicEntry[]
  selectedId: string | undefined
  faqItemsDetails: FaqItemEntry[]
}

export const ItemCards = ({ topics, selectedId, faqItemsDetails }: ItemCardsProps) => {
  const allItems = useMemo(
    () =>
      topics
        .flatMap((topic) =>
          topic.itemsCollection?.items?.map((item) =>
            faqItemsDetails.find((faqItem) => faqItem.sys?.id === item?.sys?.id),
          ),
        )
        .filter(Boolean),
    [topics, faqItemsDetails],
  )

  // We render all items for SEO purposes, but only show the selected item
  return (
    <Container id={ITEM_CARDS_ID}>
      {allItems.map((item) => (
        <ItemCard key={item.sys.id} item={item} visible={item.sys.id === selectedId} />
      ))}
      <ScrollUpButton />
    </Container>
  )
}

const Container = styled(Box)`
  scroll-margin-top: 5rem;
`
