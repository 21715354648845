import { Col, Row, spacing } from '@fortum/elemental-ui'

import type { IconId } from '@/shared/components/Icon'
import { KeyLink } from '@/shared/components/KeyLink'
import type { FaqEntry } from '@/shared/contentful/types'
import { stringifyParams } from '@/shared/utils/url'

import { ITEM_PARAM } from '../constants'

type PopularTopicLinksProps = Omit<FaqEntry, 'name' | '__typename' | 'sys'>

const handlePopularTopicClick = (id: string | undefined) => {
  const element = document.getElementById(`faq-topic-accordion-${id}`)
  if (element) {
    element.style.scrollMarginTop = '10rem'
  }
  setTimeout(() => element?.scrollIntoView({ behavior: 'smooth' }), 100)
}

export const PopularTopicLinks = ({ topics }: PopularTopicLinksProps) => {
  if (topics) {
    const topicsCount = topics.items?.length
    return (
      <Row rowGap={{ default: spacing.xs, s: spacing.xxs }}>
        {topics?.items?.map((topic) => {
          const firstItemSlug = topic?.itemsCollection?.items?.[0]?.slug
          return (
            <Col key={topic?.sys.id} s={topicsCount === 3 ? 4 : 6} l={12 / topicsCount}>
              <KeyLink
                label={topic?.title}
                href={`?${stringifyParams({ [ITEM_PARAM]: firstItemSlug ?? undefined })}`}
                icon={topic?.icon as IconId}
                dataTestId="faq-popular-topic-link"
                onClickHandler={() => handlePopularTopicClick(topic?.sys.id)}
              />
            </Col>
          )
        })}
      </Row>
    )
  }
}
