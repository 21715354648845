import { useTranslations } from 'next-intl'

import { ButtonPill, Hidden, IconArrowUp, spacing } from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'

import { ALL_TOPICS_HEADING_ID } from '../constants'

export const ScrollUpButton = () => {
  const t = useTranslations('faq')

  const backToAllTopics = () => {
    const element = document.getElementById(ALL_TOPICS_HEADING_ID)
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Hidden above="xl">
      <ButtonPill
        status="secondary"
        mt={spacing.m}
        leftIcon={<Icon icon={IconArrowUp} />}
        onClick={backToAllTopics}
      >
        {t('backToAllTopics')}
      </ButtonPill>
    </Hidden>
  )
}
