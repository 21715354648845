import { Box, Hidden, spacing } from '@fortum/elemental-ui'

import { ContentfulImage2 } from '@/shared/components/ContentfulImage2'
import { CtaBlock } from '@/shared/components/CtaBlock'
import type { CtaEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import { getSizesForColumns } from '@/shared/utils/image'

type FaqCtaProps = Omit<CtaEntry, 'sys' | '__typename'>
export const FaqCta = (props: FaqCtaProps) => {
  const { colors } = useTheme()

  return (
    <Box display="flex" backgroundColor={colors.backgroundPrimary} borderRadius={spacing.xxxs}>
      <Box
        width={{ l: props.image ? '50%' : '100%' }}
        padding={{
          default: `${spacing.xs} ${spacing.xxs}`,
          s: `${spacing.xs} ${spacing.s}`,
          l: `${spacing.m} ${spacing.m}`,
        }}
      >
        <CtaBlock {...props} />
      </Box>
      {props.image && (
        <Hidden below="l" position="relative" width="50%" aspectRatio="auto">
          <ContentfulImage2
            image={props.image}
            fill
            sizes={getSizesForColumns({ l: 6 })}
            maxWidth="750"
          />
        </Hidden>
      )}
    </Box>
  )
}
